import React from 'react'
import Style from './Style'
import Logo from '../../assets/Logo.png';
import Facebook from '../../assets/Icons/Facebook.svg';
import Twitter from '../../assets/Icons/twitter.svg';
import Call from '../../assets/Icons/Call.svg';
import Instagram from '../../assets/Icons/instagram.svg';
import Office_location from '../../assets/Icons/Office_location.svg';
const Index = () => {
  return (
    <Style>
        <div className='container'>
          <div className='footer_container'>
          <div className='firstrow'>
               <div className='first_column'>
                <img src={Logo} alt='logo'/>
                <div className='paragraph'>
                {/* We make the construction & repairing easier for you to provide best quality service to give you your free time back so that you can concentrate on what is important. */}
                </div>
               </div>
               <div className='second-column'>
                     <h5>Quick Links</h5>
                     <div className='second-column-row'>
                      <h5><a style={{color: "#fff", textDecoration: "none"}} href="#home"> Home</a></h5>
                      <h5><a style={{color: "#fff", textDecoration: "none"}} href="#services"> Service</a></h5>
                      <h5><a style={{color: "#fff", textDecoration: "none"}} href="#contact"> Contact</a></h5>
                      <h5><a style={{color: "#fff", textDecoration: "none"}} href="#aboutUs"> About</a></h5>
                     </div>
               </div>
               <div className='third-column'>
                {/* <h5>Follow Us</h5> */}
                <div className='third-column-row'>
                  {/* <img src={Facebook}/>
                  <img src={Twitter}/>
                  <img src={Instagram}/> */}
                </div>
               </div>
               <div className='fourth-column'>
                <h5>Contact Us On</h5>
                <div className='fourth-column-row'>
                  <div className='fourth-column-row-data'>
                    <img src={Call}/>
                    <p> <a style={{color: "#fff", textDecoration: "none"}} href='tel:661-220-7050'>(661) 220-7050</a></p>
                  </div>
                  <div className='fourth-column-row-data'>
                    <img src={Office_location}/>
                    <p>Serving Bakersfield and surrounding areas.</p>
                  </div>

                </div>
               </div>
               </div>
         
          <div className='line'></div>
          <div className='footr_bottom'>
            <div>Copyright © 2022 BURX CONSTRUCTION • All Rights Reserved</div>
          </div>
          </div>
        </div>
    </Style>
  )
}

export default Index