import styled from "styled-components";
import WhyChoose from "../../assets/whycurx.png";
const Style = styled.div`
  margin-top: 79px;
  .main-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    h6 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 80.52%;
      color: #0077d3;
    }
    h1 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 69.52%;
      color: #2d2d2d;
      @media (max-width: 480px) {
        font-size: 30px;
      }
    }
  }
  .service-card {
    background: #ffffff;
    box-shadow: rgb(0 0 0 / 12%) 0px 0px 3px;
    border-radius: 6px;
    width: 300px;
    @media (max-width: 320px) {
      width: 280px;
    }
    @media (max-width: 643px) {
      width: 100%;
    }
    &-img {
      /* width: 300px; */
      height: 191px;
      border-radius: 10px 10px 0px 0px;
      @media (max-width: 643px) {
        width: 100%;
        object-fit: cover;
      }
    }
    &-data {
      padding: 20px 20px 10px 20px;
      &-heading {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 80.52%;
        color: #2d2d2d;
        padding-bottom: 14px;
      }
      &-paragraph {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
        color: #5f5f5f;
      }
      &-button {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 80.52%;
        text-decoration-line: underline;
        color: #0077d3;
        margin-top: 26px;
        border: none;
        background: transparent;
      }
    }
  }
  .card-container {
    display: flex;
    /* grid-template-columns: repeat(4,1fr); */
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 60px;
    @media (max-width: 1399px) {
      justify-content: center;
      /* grid-template-columns: repeat(3,1fr); */
    }
    @media (max-width: 480px) {
      gap:30px
    }
  }
  .solution {
    margin-top: 93px;
    position: relative;
    &-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      @media (max-width: 1199px) {
        grid-template-columns: 1fr;
      }
    }
    &-img {
      border-radius: 18px 0px 0px 18px;
      height: 300px;
      width: 100%;
      @media (max-width: 1199px) {
        width: 100%;
        height: 351px;
        border-radius: 18px 18px 0px 0px;
        object-fit: cover;
      }
    }
    &-data {
      display: flex;
      flex-direction: column;
      gap: 18px;
      align-items: center;
      justify-content: center;
      background: #0077d3;
      width: 100%;
      border-radius: 0px 18px 18px 0px;
      h2 {
        padding-top:2rem;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 136%;
        color: #fff;
        max-width: 475px;
        @media (max-width: 600px) {
          font-size: 25px;
        }
      }
      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        max-width: 475px;
        padding-right: 20px;
        color: #ffffff;
        @media (max-width: 600px) {
          font-size: 16px;
        }
      }
      @media (max-width: 1199px) {
        height: 351px;
        border-radius: 0px 0px 18px 18px;
      }
      @media (max-width: 600px) {
        padding: 20px;
      }
    }
    &-logo {
      width: 130px;
      height: 130px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      /* @media (max-width:1199px){
        top: 40%;
        left: 40%;
      } */
    }
  }
  .whyChoose {
    background: url(${WhyChoose});
    width: 100%;
    min-height: 832px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: 79px;
    &-container {
      max-width: 630px;
      padding: 79px 0;
      @media (max-width: 480px) {
        padding: 0;
      }
    }
    &-heading {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 36px !important;
      line-height: 130%;
      color: #2d2d2d;
      &-small {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 80.52%;
        color: #0077d3;
        padding-bottom: 10px;
      }
    }
    &-para {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 80.52%;
      color: #555555;
      padding-top: 13px;
      line-height:1.5rem;
    }
    &-data {
      margin-top: 68px;
      display: flex;
      flex-direction: column;
      gap: 51px;
      //justify-content: space-between;
    }
    @media (max-width: 480px) {
      background: #fff;
    }
  }
  .rows {
    display: flex;
    gap: 30px;
    &-icons {
      padding-top: 10px;
      width: 80px;
      height: 80px;
    }
    &-data {
      max-width: 520px;
      h5 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 80.52%;
        color: #2d2d2d;
      }
      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 148%;
        color: #626262;
        padding-top: 10px;
      }
    }
    @media (max-width: 480px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid #929c9e;
        border-radius: 10px;
        gap: 10px;
        padding: 1rem;
      }
    
  }
`;

export default Style;
