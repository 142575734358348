import styled from "styled-components";
import bg from "../../assets/rsz_1updateimage.png"
const Style = styled.div`
  .top_nav {
    background: #0077d3;
    height: 46px;
    width: 100%;
    color: #ffffff;
    padding-top: 10px;
    a {
      color: #fff;
      text-decoration: none;
    }
    &_leftside_icons {
      display: flex;
      gap: 18px;
      img {
        height: 22px;
        width: 22px;
      }
    }
    &_rightside_icons {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
  .icons {
    display: flex;
    justify-content: space-between;
  }


  .hero_section {
    background-image: url(${bg});
    min-height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;

    // background-repeat: no-repeat;
    // background-size: cover;
    // position: relative;
  }
  // .bg-container {
  //   position: absolute; 
  //   transform: translateY(-50%);
  //   background-repeat: no-repeat;
  //   width: 100%;
  //   z-index: -1;
  //   top: 50%;
  //   background-size: 100%;
  //   left: 0; 
  // }
  // .bg-container img {
  //   width: 100%;
  //   w
  // }


  .navbar-nav {
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 40px;
    align-items: center;
    gap: 57px;
  }
  .nav-link {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 80.52%;
    color: #ffffff;
  }
  .nav-link.active {
    color: #ffa800;
    text-decoration: underline;
    font-weight: 600;
    text-underline-offset: 5px;
  }
  .estimate_button {
    width: 165px;
    height: 48px;
    background: #ffffff;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 80.52%;
    color: #0077d3;
    border: none;
    font-family: "Poppins";
    font-style: normal;
  }
  .logo {
    transform: translateY(-50%);
    width: fit-content;
    
    img {
      width: 168px;
      height: 156px;
    }
    @media (max-width: 1024px) {
      transform: translateY(-30%);
      img{
         width: 100px;
         height: 100px;
      }
    }
    @media (max-width: 480px) {
      transform: translateY(-60%);
      img{
         
         margin-top: 2.5rem 
      }
    }
  }
  .mainHeading_box {
    max-width: 600px;
    margin-top: 5px;
    h2 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 50px;
      line-height: 111.52%;
      color: #ffffff;

      @media screen and (max-width : 768px ){
        font-size: 30px;
      }
    }
  }
  .lines {
    display: flex;
    gap: 3px;
    margin-top: 38px;
    &_white {
      height: 7px;
      width: 70px;
      background: #ffffff;
      border-radius: 30px;
    }
    &_yellow {
      background: #ffa800;
      border-radius: 30px;
      height: 7px;
      width: 9px;
      border-radius: 30px;
    }
  }
  .services {
    margin-top: 50px;
    padding-bottom: 1rem
  }
  .first_row {
 
    max-width: 700px;
    display: grid;
    grid-template-columns: 200px 300px 200px;
    gap: 30px;
    @media (max-width: 800px) {
      grid-template-columns:170px 200px 150px;
    }
    @media (max-width: 600px) {
      grid-template-columns: 150px;
    }
    &_item {
      display: flex;
      align-items: center;
      gap: 13px;
      border-right: 3px solid white;
      padding-right: 66px;
      :last-child {
        border: none;
      }
      @media (max-width: 600px) {
        border: none;
      }
      h5 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 27px;
        line-height: 87.52%;
        letter-spacing: 0.02em;
        color: #ffffff;
        @media screen and (max-width : 768px ){
        font-size: 20px;
        line-height: 10px;
      }
      }
      img {
        height: 25px;
        width: 25px;
        color: #fff;
      }
    }
  }
  .second_row {
    max-width: 700px;
    display: grid;
    grid-template-columns: 200px 300px 200px;
    gap: 30px;
    margin-top: 27px;
    @media (max-width: 800px) {
      grid-template-columns:170px 200px 150px;
    }
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }
  .menuIcon {
    height: 30px;
    width: 35px;
    display: none;
  }

  .navbar-nav {
    height: 80px;
  }

  .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .toggler {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%) !important;
  }
  @media (max-width: 1024px) {
    .menuIcon {
      display: block;
    }
    .navbar .navbar-nav {
      position: absolute;
      top: 50px;
      left: 0;
      width: 100%;
      overflow: auto;
      background: white;
      flex-direction: column;
      gap: 1rem;
      justify-content: flex-start;
      height: 100vh;
      padding: 30px;
      clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
      transition: all 0.3s linear;
      a {
        color: black;
      }
      button {
        background: blue;
        color: white;
      }
    }
    .nav-link.active {
      color: blue !important;
    }
  }

  .about-us {
    background: #f2f5f8;

    /* height: 695px; */
    width: 100%;

    &-section {
      display: flex;
      align-items: center;
      gap: 82px;
      /* flex-wrap: wrap; */
      @media (max-width: 1024px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px;
      }
    }
    &-img {
      @media (max-width:600px){
        width: 100%;
      }
    }
    &-2ndcolumn {
      display: flex;
      flex-direction: column;
      max-width: 611px;
      justify-content: center;
    
      &-small {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 80.52%;
        color: #0077d3;
        padding-bottom: 0.3rem;
      }
      &-large {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 130%;
        color: #2d2d2d;
        padding-top: 7px;
        @media (max-width:800px) {
          font-size: 30px;
        }
        @media (max-width:1199px){ 
          font-size: 30px;
        }
        @media (max-width:480px) {
          font-size: 25px;
        }
      }
      &-para {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 149.52%;
        color: #626262;
        padding-bottom: 100px;
        padding-top: 26px;
        @media (max-width:800px) {
          font-size: 18px;
          padding-bottom: 80px;
        }
        @media (max-width:480px) {
          font-size: 14px;
          padding-bottom: 50px;
        }
      }
      &-btn {
        width: 190px;
        height: 48px;
        background: #0077d3;
        filter: drop-shadow(0px 9px 25px rgba(0, 0, 0, 0.16));
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 80.52%;
        color: #ffffff;
        border: none;
        border-radius: 5px;
        margin-top:1rem
        
      }
    }
  }
`;

export default Style;
