import React from 'react'
import Style from './Style'
import Header from '../layout/header/Index';
import Footer from '../layout/footer/Index';
import Contact from '../layout/contactus/Index';
import Service from '../layout/services/Index';
const Index = () => {
  return (
    <Style>
      <Header/>
      <Service/>
      <Contact/>
      <Footer/>
    </Style>
  )
}

export default Index