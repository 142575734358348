import React from "react";
import Style from "./Style";
import HomeConstruction from "../../assets/toilet.png";
import HomeRepairing from "../../assets/drain.png";
import OfficeConstruction from "../../assets/Door.png";
import OfficeRepairing from "../../assets/Remodel.png";
import Solution from "../../assets/NoJobToo.png";
import SolutionLogo from "../../assets/Icons/solution-logo.svg";
import BuildingMaterial from "../../assets/Icons/buildingMaterial.svg";
import AffordablePrice from "../../assets/Icons/affordablePrice.svg";
import ExperienceWork from "../../assets/Icons/experienceWork.svg";
import ModrenTechnologies from "../../assets/Icons/modrenTechnologies.svg";
const Index = () => {
  return (
    <Style>
      <div className="container" id="services">
        <div className="main-heading">
        
          <h1 style={{color: "#0077d3"}}>Services</h1>
        </div>
        <div className="card-container">
          <div className="service-card">
            <img src={HomeConstruction} className="service-card-img" />
            <div className="service-card-data">
              <h3 className="service-card-data-heading">Simple Home Fixes</h3>
              <p className="service-card-data-paragraph">
              We can repair your leaking toilet, faucet, shower or bathtub.

              </p>
             
            </div>
          </div>
          <div className="service-card">
            <img src={HomeRepairing} className="service-card-img" />
            <div className="service-card-data">
              <h3 className="service-card-data-heading">Cosmetic Updates</h3>
              <p className="service-card-data-paragraph">
              We specialize in replacing caulking, fixing baseboards, tiling and flooring damage.
              </p>
              
            </div>
          </div>
          <div className="service-card">
            <img src={OfficeConstruction} className="service-card-img" />
            <div className="service-card-data">
              <h3 className="service-card-data-heading">Doors, Windows & Fixtures
</h3>
              <p className="service-card-data-paragraph">
              We repair or replace windows, door / handles, fixtures and cabinets.
              </p>
              
            </div>
          </div>
          <div className="service-card">
            <img src={OfficeRepairing} className="service-card-img" />
            <div className="service-card-data">
              <h3 className="service-card-data-heading">Remodels</h3>
              <p className="service-card-data-paragraph">
              Are you finally ready to remodel your bathroom or kitchen?  Call us for a quote today!
              </p>
             
            </div>
          </div>
        </div>
        <div className="solution">
          <div className="solution-container">
            <div>
              <img src={Solution} className="solution-img" />
            </div>
            <div className="solution-data">
              <h2>No Job too big or small</h2>
              <p style={{paddingRight: "0", paddingLeft: "0.25rem"}}>
              Because we are a licensed contractor we can do complete remodels and add-ons complete with designs, permits and inspections.
              </p>
              <p>
              Call us today!
              </p>
            </div>
          </div>
          <img src={SolutionLogo} className="solution-logo" />
        </div>
      </div>
      <section className="whyChoose">
        <div className="container">
          <div className="whyChoose-container">
            <div>
              <p className="whyChoose-heading-small">
                Why Choose Us?
              </p>
              <h2 className="whyChoose-heading">
                BURX Construction Company
              </h2>
              <p className="whyChoose-para">
                We build reliable and modern stuff which last long!
              </p>
            </div>
            <div className="whyChoose-data">
                <div className="rows">
                    <div>
                    <img src={BuildingMaterial} className="rows-icons" alt="Why choose-icons"/>
                    </div>
                    <div className="rows-data">
                        <h5>Quality Service</h5>
                        <p>We pride ourselves on quality service and always aim to please.</p>
                    </div>
                </div>
                <div className="rows">
                    <div>
                    <img src={ExperienceWork} className="rows-icons" alt="Why choose-icons"/>
                    </div>
                    <div className="rows-data">
                        <h5>Experienced Team</h5>
                        <p>Our team has decades of experience in providing excellent service to our customers.</p>
                    </div>
                </div>
                <div className="rows">
                    <div>
                    <img src={AffordablePrice} className="rows-icons" alt="Why choose-icons"/>
                    </div>
                    <div className="rows-data">
                        <h5>Competitive Pricing</h5>
                        <p>We always use the best possible materials for the job for the right price.  With the costs of everything going up these days we take extra time to get the right price.
</p>
                    </div>
                </div>
                <div className="rows">
                    <div>
                    <img src={ModrenTechnologies} className="rows-icons" alt="Why choose-icons"/>
                    </div>
                    <div className="rows-data">
                        <h5>We Come to you</h5>
                        <p>We come to you so you don't even have to leave the house.  Just give us a call and we will come at your earliest convenience.</p>
                    </div>
                </div>
                
            </div>
          </div>
        </div>
      </section>
    </Style>
  );
};

export default Index;
