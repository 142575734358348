import React,{ useState } from "react";
import Style from "./Style";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal'
import Axios from "axios";
import logo from '../../assets/Logo.png';
const validationSchema = Yup.object({
  first_name: Yup.string().required("Name is required."),
  email: Yup.string()
    .email("Email should be valid")
    .required("Email is required.")
    .matches(/^(\S+$)/g, "email cannot contain blankspaces"),
  phone: Yup.string()
    .required("Phone number is required.")
    .max(10, "Maximum ten character is required.")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid."
    ),
  type: Yup.string().required("Type is required."),
  description: Yup.string().required(
    "Please leave us a message."
  ),
});
const Index = () => {
  const [show, setShow] = useState(false);
  const[res,setRes]=useState('');
  const initialValues = {
    _token: "nxaN9Sqi9E5gJpyfz98530SYgVYygakK",
    first_name: "",
    last_name: "test",
    email: "",
    phone: "",
    date: new Date().toLocaleDateString(),
    type: "",
    subject: "Subject",
    description: "",
  };
  const onSubmit = (data, { resetForm }) => {

    Axios.post("https://burxconstruction.com/laravel/public/api/contact-us", data)
      .then((response) => {
        setRes(response?.data.response);
        setShow(true);
      })
      .catch((err) => {
        toast("Your Cannot book", "top-right");
      });
    resetForm();
  };
  return (
    <div className="container" id="contact">
      <Style>
        <div className="first-column"></div>
        <div className="second-column">
          <h3>Contact Us Now</h3>
          <Formik
            initialValues={initialValues}
            enableReinitialize={false}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {(formik) => {
              return (
                <Form
                  className="contact-form"
                  name="ContactUsForm"
                  autoComplete="off"
                >
                  <div class="form-group">
                    <Field
                      type="text"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Name"
                      name="first_name"
                    />
                    <div className="error-message">
                      <ErrorMessage name="first_name" />
                    </div>
                  </div>
                  <div class="form-group">
                    <Field
                      type="number"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Phone"
                      name="phone"
                    />
                    <div className="error-message">
                      <ErrorMessage name="phone" />
                    </div>
                  </div>
                  <div class="form-group">
                    <Field
                      type="email"
                      class="form-control"
                      id="exampleFormControlInput1"
                      placeholder="name@example.com"
                      name="email"
                    />
                    <div className="error-message">
                      <ErrorMessage name="email" />
                    </div>
                  </div>
                  <div class="form-group">
                    <Field
                      class="form-select"
                      aria-label="Default select example"
                      as="select"
                      name="type"
                    >
                      <option>Please Select A Type</option>
                      <option value="Residential">Residential</option>
                      <option value="Commercial">Commercial</option>
                    </Field>
                    <div className="error-message">
                      <ErrorMessage name="type" />
                    </div>
                  </div>
                  <div class="form-group">
                    <Field
                      class="form-textarea"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Message"
                      name="description"
                      as="textarea"
                    ></Field>
                    <div className="error-message">
                      <ErrorMessage name="description" />
                    </div>
                  </div>
                  <button type="submit" className="submit-button">
                    Send
                  </button>
                </Form>
              );
            }}
          </Formik>
        </div>
        <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <div style={{textAlign:'center'}}>
        <img src={logo} alt="logo"/>
        <p style={{marginTop:'15px'}}> {res}</p>
        </div>
        </Modal.Body>
      </Modal>
      </Style>
    </div>
  );
};

export default Index;
